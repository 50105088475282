import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

const faqAccordions = () => {
  const currentAccordionID = window.location.hash;
  const containers = document.querySelectorAll('.accordion-container');

  if (0 < containers.length) {
    containers.forEach((container) => {
      let currentAccordion = null;
      const openedAccordions = [];

      if (currentAccordionID) {
        currentAccordion = container.querySelector('.ac' + currentAccordionID);
      }

      if (currentAccordion) {
        const currentGroup = currentAccordion.parentNode;
        const currentIndex = Array.prototype.indexOf.call(
          currentGroup.children,
          currentAccordion
        );
        openedAccordions.push(currentIndex);
      }

      // Ouvrir tous les accordéons par défaut dans le template single-product
      if (container.classList.contains('product__details-wrapper')) {
        console.log('bwaaa');
        const allAccordions = container.querySelectorAll('.accordion');
        allAccordions.forEach((accordion, index) => {
          openedAccordions.push(index);
        });
      }

      new Accordion(container, {
        duration: 400,
        showMultiple: true,
        openOnInit: openedAccordions,
      });
    });
  }
};

//add mutation observer to reload faqAccordions function when faq list is filtered
const accordionsInFacetTemplate = document.querySelector(
  '.listing--faq.facetwp-template'
);
const faqListChangesObserve = () => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('.listing--faq.facetwp-template');

  // Options for the observer (which mutations to observe)
  const config = { attributes: false, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        faqAccordions();
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);
};

domReady(() => {
  faqAccordions();
  if (accordionsInFacetTemplate) {
    faqListChangesObserve();
  }
});
